html {
	height:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
height:100%;
}

.JoraContainer{
	display:flex;
	flex-direction:row;
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
}

.JoraPlayer{
	flex:1;
	height:100%;
}

.JoraChat{
	flex:0 0 340px;
	display:inline-block;
	border-left:1px solid rgb(53,53,59);
	height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
